// @flow

import {
  Button,
  Col,
  Form,
  InputNumber,
  Radio,
  Row,
  Spin,
  Statistic,
} from "antd"
import React, { useEffect, useState } from "react"
import {
  getPrice,
  isEstimating,
} from "../redux/selectors/priceEstimateSelectors"
import { useDispatch, useSelector } from "react-redux"

import { AMERICAN_COUNTRIES } from "../constants"
import AntdContainer from "../components/AntdContainer"
import CountriesSelectItem from "../components/CountriesSelectItem"
import HtmlHead from "../components/HtmlHead"
import { canAutoFetchCountries } from "../redux/selectors/countriesSelectors"
import { estimatePrice } from "../redux/actions/miscActions"
import { fetchCountries } from "../redux/actions/entitiesActions"

const radioStyle = {
  display: "block",
  height: "30px",
  lineHeight: "30px",
}

const PricingForm = (props: Object) => {
  const [form] = Form.useForm()
  const [extraServiceHidden, setExtraServiceHidden] = useState(true)

  const estimating = useSelector(isEstimating)
  const price = useSelector(getPrice)
  const dispatch = useDispatch()
  // ... using free heroku dyno, server might be sleeping, so waking it up early
  const canFetchCountries = useSelector(canAutoFetchCountries)

  useEffect(() => {
    if (canFetchCountries) dispatch(fetchCountries())
  }, [canFetchCountries, dispatch])

  return (
    <Form
      form={form}
      name="pricingForm"
      layout="vertical"
      onFinish={values => dispatch(estimatePrice({ ...values }))}
      onValuesChange={(changedVals, allVals) => {
        const usOnly =
          AMERICAN_COUNTRIES.indexOf(allVals["fromCountry"]) >= 0 &&
          AMERICAN_COUNTRIES.indexOf(allVals["toCountry"]) >= 0
        setExtraServiceHidden(!usOnly)
      }}
    >
      <CountriesSelectItem
        form={form}
        label="From country"
        name="fromCountry"
      />
      <CountriesSelectItem form={form} label="To country" name="toCountry" />
      <Form.Item
        name="numPages"
        label="Number of pages"
        hasFeedback
        initialValue={1}
        rules={[
          {
            required: true,
            message: "Please input a number of pages",
          },
        ]}
      >
        <InputNumber size="large" min={1} max={60} />
      </Form.Item>
      <div className="top-space"></div>
      {!!price &&
        (estimating ? (
          <Spin />
        ) : (
          <Statistic
            title="Estimated Price"
            suffix="GBP"
            value={price}
            precision={2}
          />
        ))}
      <div className="top-space-2x"></div>
      <Form.Item>
        <Button
          className="wide-btn"
          size="large"
          type="primary"
          shape="round"
          htmlType="submit"
          loading={estimating}
        >
          {estimating ? "Checking" : "Check the price"}
        </Button>
      </Form.Item>
    </Form>
  )
}

const PricingView = () => {
  return (
    <div className="section light">
      <HtmlHead title="Mail pricing | PostSeal" />
      <div className="content-wrapper">
        <AntdContainer>
          <h1 className="ant-typography page-title">Mail pricing</h1>
          <Row>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <PricingForm />
            </Col>
          </Row>
        </AntdContainer>
      </div>
    </div>
  )
}

export default PricingView
