// @flow

import {getPriceEstimateState} from './index';

export function getPrice(state: Object) {
  return getPriceEstimateState(state).price;
}

export function isEstimating(state: Object) {
  return getPriceEstimateState(state).estimating;
}
 
